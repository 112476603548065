export const environment = {
  production: true,
  devapis: "https://devdocs.globalhuntindia.com",
  deviosapi:"https://devapis.globalhuntindia.com",
  apiKey:{'x-api-key':'XHsOEAappL2jTxuOVQirH36rDJjOGtakBHq1qrTd'},
  iSearch:"https://h7.globalhuntindia.com",
  amazonUrl:"s3://ghimldev.globalhuntindia.com",
  documentURL: 'https://view.officeapps.live.com/op/embed.aspx',
  originalDocURL: 'https://devdocs.globalhuntindia.com/',
  replaceURL: 'https://s3.ap-south-1.amazonaws.com/resumedev.globalhuntindia.com/',
};
