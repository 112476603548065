import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ApicallService } from 'src/app/service/apicall.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {
  response: any=[];
  workstart: any=[];
  show:boolean=true;
  naukriPage:boolean=false;
  errorPage:boolean=true;
  datas: any=[];
  keyskill: any;
  urlid:any;
  Linkedinurl: any;
  apiData: any;
  data: any;
  resume: any;
  office:any="office"
  resumeName: any;
  resExt: any="";
  canName: any;
  resumExt: any=[];

 
  canEmail: any;
  noName: any;
  LinkedinLink: any;
  profileNameCheck: any;
  base64code:any
  rLink: any;
  education:any=[];
  hideSkills1:boolean=true;
  hideSkills2:boolean=true;
  twitter: any;
  facebook: any;
  instagram: any;
  portal: any;
  monster:boolean=true;
  monsterUS: boolean=true;
  naukri: boolean=true;
  dice: boolean=true;
  indeed: boolean=true;
  careerBuilder: boolean=true;
  jobDiva : boolean=true;
  jobServe : boolean=true;
  other : boolean=true;
  naukriGulf : boolean=true;
  linkedIn: boolean=true;
  unknown:boolean=true;
  federated:any;
  lastModifiedOnNaukri = false;
  lastModifiedOnIndeed = false;
  lastModifiedOnDice = false;
  lastModifiedOnLinkedIn = false;
  lastModifiedOnMonsterUS = false;
  lastModifiedOnMonster = false;
  lastModifiedOnJobDiva = false;
  lastModifiedOnCareerBuilder = false;
  lastModifiedOnJobServe = false;
  lastModifiedOnNaukriGulf= false;
  lastModifiedOnOther= false;
  secondName: any;
  thirdName: any;
  navigationData: any;
  edit: any;
  Candidatedata: any;
  allEmployment: any;
  currents: any;
  sPresentCurrentlyWorking: boolean = false;
 
  constructor(
    private toastr: ToastrService,
    private apicallService:ApicallService,
    private http:HttpClient, private _route: ActivatedRoute, 
    private domSanitizer: DomSanitizer,
    private titleService:Title,
    private router: Router,
    ) { }

  ngOnInit(): void {
        this._route.paramMap.subscribe(params => {
          this.urlid = params.get('urlid');
          console.log(this.urlid ,'pppppppppppppppppp');
          });
          this.getDatas();
  }
  apiDatas(){
    return new Promise((resolve, reject) => {
      this.federated=localStorage.getItem('federated');
    this.apicallService.getCommonApiData(this.urlid,this.federated ??"null").then((data: any) => {
      this.Candidatedata = data.data.response[0];
      // console.log(this.Candidatedata.objectId,'HHHHHHHHHHHHHHHHHHHHH');
      this.allEmployment = this.Candidatedata.workexperiences;
      if (Array.isArray(this.allEmployment)) {
        for (let i = 0; i < this.allEmployment.length; i++) {
          const element = this.allEmployment[i];
          if (element.isCurrentlyWorking === 1) {
            this.currents = this.allEmployment[i];
          }
        }
      }
      localStorage.removeItem('federated');
      if(data.status ==='succeed') {
        if(data?.data === null){
          this.apicallService.getCommonApiDataWithPortal(this.urlid).then((data2)=>{
            if(data2.data !==null){
              this.navigationData=data2.data.response[0].id
      if( this.navigationData !=undefined){
        this.edit="true"
        }
        else{
          this.edit="false"
        }
              resolve(data2?.data?.response[0]);
            } else{
              this.errorPage=false;
              this.naukriPage=true;
            }
          })
        }
        else{
          this.navigationData=data.data.response[0].id
      if( this.navigationData !=undefined){
        this.edit="true"
        }
        else{
          this.edit="false"
        }
          resolve(data?.data?.response[0])
        }
      }
      else{
        this.errorPage=false;
        this.naukriPage=true;
        resolve(data=null);
      }
    })
  })
  }

async getDatas(){  
    this.data = await this.apiDatas();
      this.response=this.data;
      if(this.response===null || this.response===undefined){
        this.errorPage=false;
        this.naukriPage=true;
      }

    // ------------------- Title -------------------
    if(this.response?.first_name != undefined){
      this.secondName=this.response?.middle_name
      this.thirdName=this.response?.last_name
    if(this.response?.middle_name===undefined){
      this.secondName=''
    }
    if(this.response?.last_name===undefined ){
      this.thirdName=''
    }
   this.canName=this.response?.first_name+" "+this.secondName+" "+this.thirdName;

    }
   if(this.response !=null || this.response !=undefined){
    this.titleService.setTitle(this.canName);
   }
   if(this.response?.first_name===null || this.response?.first_name===undefined){
    this.titleService.setTitle("Profile");
   }
    // ----------------------------- Title End ---------------------
    if(this.response?.email !=null || this.response?.email !=undefined){
      if(this.response?.first_name === null || this.response?.first_name ===undefined){
        this.canEmail=this.response?.email;
        var noNam=this.canEmail.split("@");
        this.noName=noNam[0];
      }
    }

// ----------------------Portal View----------------------------------------------

  this.portal=this.response?.federated;
  if(this.portal==="monsterUS"){
    this.monsterUS=false;
    this.lastModifiedOnMonsterUS=true;
    console.log(this.lastModifiedOnMonsterUS)
    
  }
  if(this.portal==="naukriIndia"){
    this.naukri=false;
    this.lastModifiedOnNaukri=true;
  }
  if(this.portal==="dice"){
    this.dice=false;
    this.lastModifiedOnDice=true;
  }
  if(this.portal==="indeed"){
    this.indeed=false;
    this.lastModifiedOnIndeed=true;
  }
  if(this.portal==="linkedIn"){
    this.linkedIn=false;
    this.lastModifiedOnLinkedIn=true;
    
  }
  if(this.portal==="monster"){
    this.monster=false;
    this.lastModifiedOnMonster=true;
    
  }
  if(this.portal==="jobdiva"){
    this.jobDiva=false;
    this.lastModifiedOnJobDiva=true;
    
  }
  if(this.portal==="carrerbuilder"){
    this.careerBuilder=false;
    this.lastModifiedOnCareerBuilder=true;
    
  }
  if(this.portal==="jobserve"){
    this.jobServe=false;
    this.lastModifiedOnJobServe=true;
    
  }
  if(this.portal==="gulf"){
    this.naukriGulf=false;
    this.lastModifiedOnNaukriGulf=true;
    
  }
  if(this.portal==="other"){
    this.other=false;
    this.lastModifiedOnOther=true;
    
  }
  if(this.portal !="linkedIn" && this.portal !="indeed" && this.portal !="dice" && this.portal !="naukriIndia" && this.portal !="monsterUS" && this.portal !="monster" &&  this.portal !="jobdiva"  &&  this.portal !="carrerbuilder" &&  this.portal !="jobserve" &&  this.portal !="gulf" &&  this.portal !="other"){
    this.unknown=false;
  } 
// ----------------------End Portal View----------------------------------------------

// ----------------------Resume View----------------------------------------------
if(this.response?.resumeName !=null){
    this.resumeName=this.response?.resumeName;
    var resName=this.resumeName.split(".")
    resName.forEach((data:any)=>{
      this.resumExt.push(data)
    })
    var nemeExtention=this.resumExt?.length-1
    this.resumeName=resName[nemeExtention];
    if(this.resumeName=="pdf"){
      this.resExt="url";
    }else{
      this.resExt="office";
    }
    this.resume=`${this.response?.resumeURL}#toolbar=0`;
    // console.log(this.resume,'kkkkkkkkkkkkkkk')
  }

// ----------------------End Resume View----------------------------------------------

// ----------------------Social Media----------------------------------------------
    
    if(this.response?.connectSocialNetwork !=null){
      this.response?.connectSocialNetwork?.forEach((link:any)=>{
        if(link?.url !=null || link?.url !=undefined || link?.url !=""){
          if(link?.name==="linkedin"){
          this.LinkedinLink=link;
          }
           if(link?.name==="twitter"){
          this.twitter=link?.url;
          }
          if(link?.name==="facebook"){
            this.facebook=link?.url;;
            }
        }
      })
          if(this.LinkedinLink?.url !=null){
            var linkId=this.LinkedinLink?.url;
            var fName=this.response?.first_name;
            var seachName=fName.toLowerCase(fName)
            if(linkId.includes(seachName) ===true){
             var profileCheck=this.LinkedinLink?.url;
               this.profileNameCheck=profileCheck.split("?");
               
              if(this.profileNameCheck[0].includes("//") ===true){
                this.Linkedinurl=this.profileNameCheck[0];
              }else{
                this.Linkedinurl="//"+this.profileNameCheck[0];
              }
            }
        }
    }
// ----------------------End Social Media----------------------------------------------


    // ------------------- Skills -----------------------------------------------------
    if(this.response?.skills){
      this.keyskill=this.response?.skills;
      if(this.keyskill[0]?.experience){
        this.hideSkills2=false;   
      }else{
        this.hideSkills1=false; 
      }
    }
    
    // ------------------- End Other Skills-------------------

    if(this.resume===undefined){
      var click=document.getElementById('clickme');
        click?.click()
    }
  }

  // ---------------------------- Re-Direct to Internal Search-----------------------------------------------------
  redirectToInSearch(){
    this.rLink=`${environment.iSearch}/#/InternalSearch/candidateProfileView/${this.navigationData}?data=primary`;
  }
  // ---------------------------- End Re-Direct to Internal Search-----------------------------------------------------

  // ------------------------- Download Resume ------------------------------------------------------------------------
  async downloadResume() {
    let blob;
    await this.apicallService.getResumeDowload(this.urlid).then(data => {
      // console.log(data.data);
      
      if (data.data !== null) {
        this.base64code = data?.data?.response?.resume_data;
        const ext = this.resumeName;
        if (this.base64code === 'not found.') {
          alert('Resume Not Found!');
        }
        else {
          const byteArray = new Uint8Array(atob(this.base64code).split('').map(char => char.charCodeAt(0)));
            if (ext === 'doc' || ext === 'pdf' || ext === 'docx' || ext === this.urlid) {
            let a = document.createElement("a");
            if(this.canName === undefined || this.canName === null){
              this.canName="profile"
            }
            if(ext === 'doc'){
              // a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
              a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/msword' }));
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');  
              
            }
            else if(ext === 'pdf'){
              a.href = URL.createObjectURL( blob = new Blob([byteArray],  { type: 'application/pdf' }));
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');            
            }
            else if(ext === 'docx'){
              // a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
              a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/msword' }));
              
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');
            }
            else if (ext === this.urlid) {
              a.href = URL.createObjectURL( blob = new Blob([byteArray],  { type: 'application/pdf' })) || URL.createObjectURL( blob = new Blob([byteArray],  { type: 'application/msword' }));
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');
            }
          }
        }
      }
      else {
        this.toastr.warning('Resume Not Found!');
      }
    });
  }
}
